<template>
  <div>
    <div class="position-relative mb-3">
      <div class="position-absolute mt-2 pbz-font body-md-bold">
        {{ $t('export_history_page.title') }}
      </div>
      <div class="d-flex justify-content-center align-items-center">
        <a-input-search
          class="col-4"
          allow-clear
          size="large"
          :placeholder="$t('utils.search')"
          :value="querySearch"
          @change="onSearch"
        />
        <a-button
          size="large"
          icon="filter"
          class="ml-2"
          @click="showModalFilter = true"
        >
          {{ $t('utils.filter') }}
        </a-button>
      </div>
    </div>

    <div>
      <a-button
        v-for="state in STATES"
        :key="state"
        shape="round"
        :disabled="activeStatus === state"
        :class="['mr-1', activeStatus === state && 'bg-white text-danger border-danger']"
        @click="changeRoute({ status: state })"
      >
        {{ $t('export_history_page.' + state) }}
      </a-button>
    </div>

    <a-skeleton
      active
      :title="false"
      :loading="loadingList"
      :paragraph="{ rows: 1, width: 180 }"
      class="w-auto mt-4 mb-3 skeleton-total-history"
    >
      <h6>
        {{ $t('export_history_page.total_caption', { total: total_row }) }}
      </h6>
    </a-skeleton>

    <TableBorder class="mb-2" table-class="table-hover">
      <template #thead>
        <th
          v-for="(th, thIndex) in THEAD"
          :key="th.label + thIndex"
          :width="th.width"
        >
          {{ th.label ? $t('export_history_page.' + th.label) : '' }}
        </th>
      </template>
      <!-- // Use this code when endpoint's ready
      <tbody v-if="data.length">
        <tr v-for="val in data" :key="val.id">
          <td>
            <time v-if="val.created_at" class="mw-100 d-inline-block align-middle text-truncate">
              {{ $moment(val.created_at).format('DD MMM YYYY - HH.MM') }}
            </time>
          </td>
          <td>{{ val.user }}</td>
          <td>{{ val.menu }}</td>
          <td>
            <a-button
              v-if="val.file_name"
              type="link"
              size="small"
              :class="val.status === 'DONE' ? 'text-primary' : 'text-muted'"
              :disabled="val.status === 'FAILED'"
            >
              {{ val.file_name }}
            </a-button>
          </td>
          <td width="120">
            <a-button 
              v-if="val.status === 'FAILED'"
              size="small"
              icon="sync"
              class="border-danger text-danger"
              :style="{ 'padding-left': itemExport.id === val.id ? '21px' : undefined }"
              :loading="itemExport.id === val.id"
              @click="tryAgainExport(val)"
            >
              {{ $t('export_history_page.try_again') }}
            </a-button>
          </td>
          <td>
            <div
              v-if="val.status"
              class="p-1 rounded-pill text-center text-white text-capitalize"
              :class="val.status === 'DONE' ? 'bg-success':'bg-danger'"
            >
              {{ $t('export_history_page.' + val.status.toLowerCase()) }}
            </div>
          </td>
        </tr>
      </tbody> -->
    </TableBorder>

    <!-- // Use this code when endpoint's ready
    <LoadingListTable v-if="loadingList" />
    <template v-else>
      <ErrorInfo v-if="errorRows" :error-type="errorRows" />
      <EmptyInfo
        v-else-if="!errorRows && !data.length"
        :title="$t('export_history_page.empty_title')"
      />
    </template> -->
    <EmptyInfo
      title="Coming Soon"
    />

    <Pagination
      v-if="data.length"
      class="text-right mt-3"
      :total="total_row"
      :page-size="PAGE_SIZE"
      :total-length-per-page="data.length"
      :default-page="currentPage"
      @changePage="page => changeRoute({ page })"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import TableBorder from '@/components/Table/TableBorder.vue'
import Pagination from '@/components/Pagination/index.vue'
import EmptyInfo from '@/components/EmptyInfo'

export default defineComponent({
  components: {
    TableBorder,
    Pagination,
    EmptyInfo,
  },
  data() {
    return {
      STATES: ['all', 'product', 'order', 'fulfillment', 'inventory', 'formula'],
      // 'date', 'user', 'menu', 'file_export', '', 'status'
      THEAD: [
        { label: 'date', width: 160 },
        { label: 'user', width: 170 },
        { label: 'menu', width: 170 },
        { label: 'file_export', width: 300 },
        { label: '', width: 100 },
        { label: 'status', width: 100 },
      ],
      PAGE_SIZE: 10, // 20
      data: [],
      total_row: 0,
      errorRows: false,
      loadingList: false,
      itemExport: {},
      showModalFilter: false,
      querySearch: '',
    }
  },
  computed: {
    currentPage() {
      return +this.$route.query.page || 1
    },
    activeStatus() {
      return this.$route.query.status || 'all'
    },
    permission() {
      return this.$store.getters['user/can'](this.$route.meta.key)
    },
  },
  watch: {
    '$route.query'() {
      this.getList()
    },
    // permission(newValue) {
    //   if(!newValue.length) {
    //     this.$router.push({path: '/error/403', query: {...this.$route.query}})
    //   }
    // },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      const { q } = this.$route.query

      this.loadingList = true
      this.data = []
      this.total_row = 0
      this.querySearch = q || ''

      /** @NOTES : Dev Only for dummy xhr */
      setTimeout(() => {
        this.data = Array.from({ length: 0 }).map((v, i) => ({
          id: i + 1,
          created_at: '2022-07-13T04:34:14.397+00:00', // 27 Jan 2021 - 18.00
          user: 'Nobita',
          menu: 'Pesanan',
          file_name: '11_orders_export_20220105143333.xlsx',
          key_name: 'sales/11_orders_export_20220105143333.xlsx',
          status: i % 2 ? 'DONE' : 'FAILED', // DONE | 'selesai' : 'gagal'
        }))
        this.total_row = 0
        this.loadingList = false
      }, 2e3)
    },
    onSearch(e) {
      const value = e.target.value
      this.querySearch = value
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const valTrim = value.trim()
        const { workspace_id, business_id, q } = this.$route.query
        if (q !== valTrim) { // Prevent push if same value
          this.$router.push({
            query: {
              workspace_id,
              business_id,
              page: undefined,
              q: valTrim.length ? valTrim : undefined,
            },
          })
        }
      }, 500)
    },
    changeRoute(q) {
      this.$router.push({ query: { ...this.$route.query, ...q } })
    },
    tryAgainExport(val) {
      this.itemExport = val
      setTimeout(() => { // DUMMY XHR
        this.itemExport = {}
      }, 2e3)
    },
  },
})
</script>

<style lang="scss">
.skeleton-total-history ul {
  margin: 0
}
.skeleton-total-history li {
  height: 18px !important;
  border: 1px solid #ddd
}
</style>
